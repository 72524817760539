import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import _, {cloneDeep} from "lodash";
import React, {useContext} from "react";
//images
import Cup from "../../../../images/cup_lottery.svg";
//components
import Place from "../places/place";
//core
import {LayoutContext} from "../../../../core/Contexts";
import {ILotteryPlace, ILotteryPlaceBlock} from "../../../../core/models/Lottery";
//stores
import Store from "../../../../stores";
import Prize from "../prize";

export default React.memo(function Awards2() {
    const contextLayout = useContext(LayoutContext);
    return (
        <Typography as="div" className="bl-lot-awards">
            <Typography as="div" className="title">
                {_.lte(contextLayout?.width ?? 0, 431) ? "Список призов" : " Призовые места"}
            </Typography>
            <Prize />
            <Row className="row-places">
                <Typography as="div" className="bl-places-one-till-five">
                    {_.get(Store.lottery?.lottery, "awards_info.placeBlocks[0].places[0].image") ? (
                        <Typography as="div" className="bl-places-one-till-five__images">
                            {_.chain(_.get(Store.lottery?.lottery, "awards_info.placeBlocks") ?? [])
                                .take(2)
                                .map(({places}, k) => (
                                    <Typography as="div" className="places-row" key={k}>
                                        {_.map(places, ({image}: ILotteryPlace) => (
                                            <React.Fragment key={image}>
                                                <Typography
                                                    as="div"
                                                    dangerouslySetInnerHTML={{
                                                        __html: `<img src="${image!}"  width="189" alt="" />`
                                                    }}
                                                />
                                            </React.Fragment>
                                        ))}
                                    </Typography>
                                ))
                                .valueOf()}{" "}
                        </Typography>
                    ) : (
                        _.chain(
                            _.get(Store.lottery?.lottery, "awards_info.placeBlocks[0].places") ?? []
                        )
                            .filter(({title}) => /^[\d]{1}\sместо:?.*$/g.test(title))
                            .orderBy(["title"], ["asc"])
                            .chunk(3)
                            .map((places, k) => (
                                <Typography as="div" className="places-row" key={k}>
                                    {_.map(places, (i: ILotteryPlace) => {
                                        const updated = cloneDeep(i);

                                        if (/^[\d]{1}\sместо:.*$/g.test(updated.title)) {
                                            updated.sum = _.replace(
                                                updated.title,
                                                /.*:(.*)$/gi,
                                                "$1"
                                            ).trim();
                                            updated.title = _.replace(
                                                updated.title,
                                                /:.*$/gi,
                                                ""
                                            ).trim();
                                        }

                                        return (
                                            <React.Fragment key={updated.title}>
                                                <Place {...updated} />
                                            </React.Fragment>
                                        );
                                    })}
                                </Typography>
                            ))

                            .valueOf()
                    )}
                </Typography>
                {_.lte(contextLayout?.width ?? 0, 431) ? (
                    <Col span={24}>
                        <Typography as="div" className="title-all-prizes">
                            Призовые места 6 – 5000
                        </Typography>
                    </Col>
                ) : null}
                <Col span={24} className="bl-all-prizes">
                    <Typography as="div" className="bl-places-six-till-end">
                        {_.chain(_.get(Store.lottery?.lottery, "awards_info.placeBlocks") ?? [])
                            .map((i: ILotteryPlaceBlock) =>
                                _.chain(i.places)
                                    .filter(
                                        ({type, title}) =>
                                            _.isEqual(type, "line") &&
                                            !/^[\d]{1}\sместо:?.*$/g.test(title)
                                    )
                                    .map((i: ILotteryPlace, k) => (
                                        <Typography as="div" className="place-item" key={i.title}>
                                            <img alt="" src={Cup} width={22} />
                                            <Typography as="div" className="place-item__title">
                                                {i.title}
                                            </Typography>
                                        </Typography>
                                    ))
                                    .valueOf()
                            )

                            .valueOf()}
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
});
