import React, {useContext, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Col, Row, Title, Typography} from "@bezlimit/bezlimit-ui";
import {Table} from "antd";
import _ from "lodash";
import moment from "moment";
import {isMobile} from "react-device-detect";
import "./style.scss";
import "moment/locale/ru";
//service
import LotteryPhonesService from "../../../services/LotteryPhonesService";
import LotteryService from "../../../services/LotteryService";
// store
import Store from "../../../stores";
//core
import {LayoutContext} from "../../../core/Contexts";
//component
import LotteryEmptyResult from "./empty";
import LotterySkeleton from "./skeleton";
import Search from "./search";
import Modal from "./modal";
import Awards from "./awardsDrawer";
import Prize from "./prize";
import SubTitle from "./subTitleLottry";
import EmptyNoPassport from "./emptyNoPassport";
import ListPhones from "./listPhones";
import AwardsBtn from "./awardsBtn";
import ConditionBtn from "./conditionBtn";
import BlSummury from "./blSummary";
import Timer from "./timerNew/timer";
import Places from "./places";

moment.locale("ru");

const getBalls = (code: string | undefined): JSX.Element => {
    if (!code) {
        return <p>{"Код розыгрыша: - "}</p>;
    }
    const balls = (
        <>
            {(code.split(".") || []).map((item, ind) => {
                return <div className={`balls-${ind % 3}`}>{item}</div>;
            })}
        </>
    );
    return (
        <div className="bl-balls">
            <p>{"Код розыгрыша:"}</p>
            <Typography as="div" className="bl-balls-wrapper">
                {balls}
            </Typography>
        </div>
    );
};

export const Lottery: React.FC = observer(() => {
    const {t} = useTranslation();
    const timeout: any = useRef();
    const [timerRun, setTimerRun] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [data, setData] = useState<ILotteryPhoneView[]>([]);
    const contextLayout = useContext(LayoutContext);
    const {Column} = Table;
    const [clicked, setClicked] = useState(false);
    const [isDescription, setIsDescription] = useState(false);
    const [drawerContent, setDrawerContent] = useState<any>();
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const summary = Store.lottery?.summary;
    const started = Store.lottery?.lottery?.started_at;
    const startedStr = started ? moment(started).format("LLL") : "";
    const link = Store.lottery?.lottery?.link;
    const phones = Store.lotteryPhones?.items ?? [];
    const noPassport = (Store.user?.personal_data?.passport_status ?? "") !== "confirmed";

    const MSK_UTC_OFFSET = 180;

    useEffect(() => {
        LotteryService.getLotteryInfo(isMobile);
        LotteryService.getSummary();
        LotteryPhonesService.fetchlotteryPhones();
    }, []);

    useEffect(() => {
        if (!_.isNil(started)) {
            clearTimeout(timeout.current);
            const interval = 1000;
            const eventTime = moment(started);
            const currentTime = moment();
            const localUtcOffset = currentTime.utcOffset();
            const utcOffsetDiff = localUtcOffset - MSK_UTC_OFFSET;
            const eventTimeUnix = eventTime.add(utcOffsetDiff, "minutes").unix(); // переводим в локальное
            const currentTimeUnix = currentTime.unix();
            const diffTime = eventTimeUnix - currentTimeUnix;
            let duration: any = moment.duration(diffTime * 1000, "milliseconds");

            timeout.current = setTimeout(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                if (_.lt(duration.asMilliseconds(), 0)) {
                    !timerRun && setTimerRun(true);
                } else {
                    clearTimeout(timeout.current);
                    timerRun && setTimerRun(false);
                }
            }, interval);
        }
        return () => {
            clearTimeout(timeout.current);
        };
    }, [timerRun, started]);

    useEffect(() => {
        let newData = (phones || []).map((item, ind) => {
            return {
                id: ind,
                phone: item.phone,
                phone_mob: item.phone,
                activated_at: moment(item.activated_at).format("DD.MM.YYYY"),
                code: getBalls(item.code),
                activated_code: {
                    activated_at: moment(item.activated_at).format("DD.MM.YYYY"),
                    code: getBalls(item.code)
                }
            } as ILotteryPhoneView;
        });
        if (!clicked) {
            newData = newData.slice(0, 6);
        }
        setData(newData);
    }, [phones, clicked]);

    const onClick = async () => {
        setClicked(true);
        if (data.length < 25) {
            return;
        }
        setLoading(true);
        try {
            const options = {
                page: LotteryPhonesService.currentPage + 1
            };

            await LotteryPhonesService.loadMorelotteryPhones(options);
        } finally {
            setLoading(false);
        }
    };

    const onOpen = (type: string) => {
        ref.current?.querySelectorAll(".actions .ant-btn").forEach((i: any) => {
            i.blur();
        });

        switch (type) {
            case "awards":
                setIsDescription(false);
                setDrawerContent(<Awards />);
                break;
            default:
                setIsDescription(true);
                setDrawerContent(
                    <Typography as="div" className="bl-lot-awards">
                        {_.lte(contextLayout?.width ?? 0, 431) ? (
                            <Typography as="div" className="title">
                                {"Подробные условия \nпроведения"}
                            </Typography>
                        ) : (
                            <Typography as="div" className="title">
                                {"Подробные условия проведения"}
                            </Typography>
                        )}

                        <div
                            dangerouslySetInnerHTML={{
                                __html: Store.lottery?.lottery?.description ?? ""
                            }}
                        ></div>
                    </Typography>
                );
                break;
        }

        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);

        setTimeout(() => {
            setIsDescription(false);
        }, 200);
    };

    const getTable = (isMobile: boolean) => {
        if (!isMobile) {
            return (
                <Table
                    className="lottery-table"
                    dataSource={data}
                    pagination={false}
                    rowKey={(record) => record.id}
                >
                    <Column
                        dataIndex="phone"
                        key="phone"
                        className={`col-phone ${noPassport ? "inActive" : "gold1"}`}
                    />
                    <Column dataIndex="activated_at" key="activated_at" className="col-activated" />
                    <Column dataIndex="code" key="code" className="col-code" />
                </Table>
            );
        }

        return (
            <Table
                className="lottery-table"
                dataSource={data}
                pagination={false}
                rowKey={(record) => record.id}
            >
                <Column
                    dataIndex="phone_mob"
                    key="phone_mob"
                    className={`col-phone ${noPassport ? "inActive" : "gold1"}`}
                />
                <Column
                    dataIndex="activated_code"
                    key="activated_code"
                    className="col-activated-code"
                    render={(activated_code: ActivateCodeView) => (
                        <>
                            <Typography className="col-activated">
                                {activated_code.activated_at}
                            </Typography>
                            {/* <Typography className="col-code">{activated_code.code}</Typography> */}
                        </>
                    )}
                />
            </Table>
        );
    };

    return (
        <div className="bl-lottery-page">
            <Title title={"Первоапрельский \nРозыгрыш"} className="gold"/>
            <Typography as="div" className="lottery-container">
                <SubTitle />

                {!isMobile ? (
                    <>
                        <Row gutter={[38, 38]}>
                            <Col xs={24} lg={24}>
                                <Prize />
                            </Col>
                            <Col xs={24} lg={12}>
                                <Places />
                            </Col>
                            <Col xs={24} lg={12} className="col-lottery-rules-container">
                                <Typography as="div" className="lottery-timer-container mb-36">
                                    <Typography as="div" className="lottery-rules-container">
                                        <AwardsBtn onOpen={onOpen} />
                                        <ConditionBtn onOpen={onOpen} />
                                    </Typography>
                                </Typography>
                            </Col>
                            <Col xs={24} lg={14}>
                                <Typography as="div" className="countdown">
                                    {started && (
                                        <div className="lottery-timer-timer">
                                            <Timer />
                                        </div>
                                    )}
                                    {timerRun === false && !link && (
                                        <div className="lottery-timer-timer timer-container mt-6">
                                            <Typography className="lottery-timer-text gold">
                                                {t("Скоро")}
                                            </Typography>
                                        </div>
                                    )}
                                    {timerRun === undefined && <></>}
                                </Typography>
                            </Col>
                            <Col xs={24} lg={10}>
                                <BlSummury summary={summary} />
                            </Col>
                            {_.find(phones ?? [], ({code}) => !_.isNil(code) && !!code) ||
                            (!phones.length && isSearch) ? (
                                <Col xs={24}>
                                    <Search
                                        setLoading={setLoadingSkeleton}
                                        setIsSearch={setIsSearch}
                                    />
                                </Col>
                            ) : null}
                        </Row>

                        {noPassport && <EmptyNoPassport />}
                        <Typography as="div" className="lottery-result-container">
                            {_.size(phones) ? (
                                <>
                                    {loadingSkeleton ? (
                                        <LotterySkeleton />
                                    ) : (
                                        <ListPhones
                                            loading={loading}
                                            data={data}
                                            getTable={getTable}
                                            onClick={onClick}
                                            isMobile={isMobile}
                                        />
                                    )}
                                </>
                            ) : (
                                <LotteryEmptyResult isSearch={isSearch} />
                            )}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Row gutter={[18, 18]}>
                            <Col xs={24} lg={24}>
                                <Prize />
                            </Col>
                            <Col xs={24} lg={24}>
                                <Typography as="div" className="text-prizes">
                                    Призовые места
                                </Typography>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Places />
                            </Col>
                            <Col xs={24} lg={12} className="vid">
                                <Typography as="div" className="lottery-timer-container mb-36">
                                    <Typography as="div" className="lottery-rules-container">
                                        <AwardsBtn onOpen={onOpen} />
                                        <Typography as="div" className="countdown">
                                            {started && (
                                                <div className="lottery-timer-timer">
                                                    <Timer />
                                                </div>
                                            )}
                                            {timerRun === false && !link && (
                                                <div className="lottery-timer-timer timer-container mt-6">
                                                    <Typography className="lottery-timer-text gold">
                                                        {t("Скоро")}
                                                    </Typography>
                                                </div>
                                            )}
                                            {timerRun === undefined && <></>}
                                        </Typography>
                                        <ConditionBtn onOpen={onOpen} />
                                    </Typography>
                                </Typography>
                            </Col>
                            <Col xs={24}>
                                <BlSummury summary={summary} />
                            </Col>
                            {_.find(phones ?? [], ({code}) => !_.isNil(code) && !!code) ||
                            (!phones.length && isSearch) ? (
                                <Col xs={24}>
                                    <Search
                                        setLoading={setLoadingSkeleton}
                                        setIsSearch={setIsSearch}
                                    />
                                </Col>
                            ) : null}
                        </Row>
                        {noPassport && <EmptyNoPassport />}
                        <Typography as="div" className="lottery-result-container">
                            {_.size(phones) ? (
                                <>
                                    {loadingSkeleton ? (
                                        <LotterySkeleton />
                                    ) : (
                                        <ListPhones
                                            loading={loading}
                                            data={data}
                                            getTable={getTable}
                                            onClick={onClick}
                                            isMobile={isMobile}
                                        />
                                    )}
                                </>
                            ) : (
                                <LotteryEmptyResult isSearch={isSearch} />
                            )}
                        </Typography>
                    </>
                )}
            </Typography>
            <Modal
                drawerContent={drawerContent}
                isDescription={isDescription}
                open={open}
                onClose={onClose}
            />
        </div>
    );
});

export interface ILotteryPhoneView {
    id: number;
    phone: number;
    phone_mob: number;
    activated_at?: string; // datetime "DD.MM.YYYY",
    code?: JSX.Element;
    activated_code?: ActivateCodeView;
}

interface ActivateCodeView {
    activated_at?: string; // datetime "DD.MM.YYYY",
    code?: JSX.Element;
}
